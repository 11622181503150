export default {
  init: (): void => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

    Array.from(document.querySelectorAll<HTMLInputElement>('input[data-time-zone-set]'))
      .forEach((el) => {
        el.value = timeZone
      })
  }
}
